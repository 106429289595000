import { displayErrorMessage } from '../helpers/displayError';
import { isNullUndefinedEmpty } from '../helpers/helpers';
import userStateManager from '../helpers/stateManager';

let d3;

export const component = async () => {
    const momentModule = await import('moment-mini');
    window.moment = momentModule.default;

    d3 = await import('d3-shape');

    const serviceHistoryGraph = document.getElementById('service-history-graph');

    //move elements if mobile
    if (window.innerWidth < 768) {
        const $myProfile = $('.my-profile');
        const $onthispage = $('.on-this-page');
        const $callout = $myProfile.next('.callout-box');

        if ($myProfile.length && $onthispage.length) {
            $onthispage.after($callout).after($myProfile);
        }

        //service history should be open by default
        $('.my-profile__heading.-btn').click();
    }

    if (serviceHistoryGraph) {
        userStateManager.getUserProfileData(function (data) {
            if (!isNullUndefinedEmpty(data.ProfileInfo)) {
                if (data && data.apiProblemOccured) {
                    displayErrorMessage($(serviceHistoryGraph).parent(), true, false);
                    return;
                }

                if (!isNullUndefinedEmpty(data.ProfileInfo.TotalLengthOfService) && !isNullUndefinedEmpty(data.ProfileInfo.Tenures)) {
                    try {
                        const graphData = data.ProfileInfo.Tenures.slice(0, 3);
                        renderGraph(serviceHistoryGraph, graphData, data.ProfileInfo.TotalLengthOfService);
                    } catch (error) {
                        console.error('There was an error setting up the service history graph: ', error);
                        serviceHistoryGraph.innerHTML = '<p>There was an issue displaying this graph.</p>';
                    }
                } else {
                }
            }
        });

        //Trigger expanding the accordions for anchor CTAs
        const $allHistoryBtn = $('.all-my-service-history-link');
        const $allHistoryTable = $('#my-service-history-table');
        const $allHistoryAccordion = $('#My-Service-History');
        const $allIncidentsBtn = $('.all-my-incidents-link');
        const $allIncidentsTable = $('#my-incidents-table');
        const $allIncidentsAccordion = $('#My-Incidents');

        $allHistoryBtn.on('click', function (e) {
            if ($allHistoryAccordion.attr('aria-expanded') === 'false') {
                $allHistoryAccordion.attr('aria-expanded', 'true');
                $allHistoryTable.parents('.collapse').addClass('in').removeAttr('style');
            }
        });

        $allIncidentsBtn.on('click', function (e) {
            if ($allIncidentsAccordion.attr('aria-expanded') === 'false') {
                $allIncidentsAccordion.attr('aria-expanded', 'true');
                $allIncidentsTable.parents('.collapse').addClass('in').removeAttr('style');
            }
        });
    }
};

function renderGraph(serviceHistoryGraph, graphData, totalServiceDays) {
    const arc = d3.arc(); // See d3 shapes repo for more details: https://github.com/d3/d3-shape

    //The first 3 rings are use for the background, these are full 360 rings and not arcs.
    //Render static bg rings for length of graph data
    var staticRings = graphData.map((item, index) => {
        return arc({
            innerRadius: 60 - index * 25,
            outerRadius: 80 - index * 25,
            startAngle: 0,
            endAngle: Math.PI * 2,
        });
    });

    // These arcs corresponds to the (up to three values), starting from the outer ring going down to the most innner.
    var serviceItemsArcs = graphData.map((item, index) => {
        // const startDate = moment(item.StartDate);
        // const endDate = (item.EndDate !== null) ? moment(item.EndDate) : moment();
        // const duration = moment.duration(endDate.diff(startDate)).asDays();
        const percent = item.LengthOfService / totalServiceDays;
        const endAngleDegrees = Math.floor(percent * 360);

        // console.log('Duration for this service: ', duration);
        // console.log('Percent for this service: ', percent);
        // console.log('endAngleDegrees for this service: ', endAngleDegrees);

        var arcPath = arc({
            innerRadius: 60 - index * 25,
            outerRadius: 80 - index * 25,
            startAngle: 0,
            endAngle: endAngleDegrees * (Math.PI / 180), //Convert degrees to radians
        });

        //Set up number label
        if (arcPath.indexOf('L') > 0) {
            const textCoords = arcPath.split('L')[1].split('A')[0]; //Get the position of the last line that closes the arc if it exists
            const coords = parseCoords(textCoords);

            return `
				<g data-index="${index}" class="arc-path">
					<path fill="#5f7452" d="${arcPath}"></path>
					<g transform="translate(${coords.x},${coords.y}) ">
						<g transform="rotate(${endAngleDegrees - 90})">
							<g transform="translate(10,0)">
								<circle cx="0" cy="0" r="12" class="number-label-bg" style="filter:url(#dropshadow)"/>
								<text x="0" y="4" text-anchor="middle" class="number-label" transform="rotate(${-endAngleDegrees + 90})">${index + 1}</text>
							</g>
						</g>
					</g>
				</g>
			`;
        } else {
            return `
				<g data-index="${index}" class="arc-path">
					<path fill="#5f7452" d="${arcPath}"></path>
				</g>
			`;
        }
    });

    const staticRingsHtml = staticRings.map((content) => getStaticRingHtml(content));
    // adds the constructed SVG graph to the dom element that will be housing this graph.
    // NOTE: if you tweak the width and height, don't forget the transforms (by default the origin is 0, 0) so the circle
    // will sit in the corner, hence there is the transform="translate(x, y)" on the paths to move it to the center.

    serviceHistoryGraph.innerHTML = `
		<svg class="service-graph" width="180" height="180" viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false">
			<title>A graph representing your service history</title>
			<filter id="dropshadow" height="130%">
				<feGaussianBlur in="SourceAlpha" stdDeviation="2"/> <!-- stdDeviation is how much to blur -->
				<feOffset dx="0" dy="0" result="offsetblur"/> <!-- how much to offset -->
				<feComponentTransfer>
					<feFuncA type="linear" slope="0.5"/> <!-- slope is the opacity of the shadow -->
				</feComponentTransfer>
				<feMerge> 
					<feMergeNode/> <!-- this contains the offset blurred image -->
					<feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->
				</feMerge>
			</filter>
			<g transform="translate(90,90)">
				${staticRingsHtml.join('')}
				${serviceItemsArcs.join('')}
			</g>
		</svg>
	`;

    let activeIndex = 0; // used to hanlde hover of the service item and the arc values.
    const serviceHistoryList = document.querySelector('.service-history-list');

    if (serviceHistoryList && graphData.length) {
        //console.log(graphData);
        const listHtml = graphData.map((content, index) => getServiceHistoryItem(content, index));
        $(serviceHistoryList).html(listHtml);

        //List mouse events
        const serviceHistoryItem = $('.service-history-item');
        serviceHistoryItem.on('mouseenter', onServiceItemEnter);
        serviceHistoryItem.on('mouseleave', onServiceItemLeave);
        serviceHistoryItem.on('click touchend', handleClickAndTouch);

        //TODO - Some of these selectors are native and some are jQuery...
        const allArcPath = document.querySelectorAll('.arc-path');
        const historyItems = document.querySelectorAll('.service-history-item');
        highlightServiceItem(activeIndex, historyItems);
        highlightArcPath(activeIndex, allArcPath);

        //Path mouse events
        const arcPath = $('.arc-path');
        arcPath.on('mouseenter', onArcEnter);
        arcPath.on('mouseleave', onArcLeave);
        arcPath.on('click touchend', handleClickAndTouch);
    }
}

function handleClickAndTouch(event) {
    const index = parseIndex(event.currentTarget);
    removeAllActiveItems();
    highlighSelectedItem(index);
}

function getStaticRingHtml(content) {
    return `<path class="background-path" fill="#08436f" d="${content}"></path>`;
}

function getServiceHistoryItem(content, index) {
    var startDate = moment(content.StartDate);

    var endDateText = content.EndDate !== null ? moment(content.EndDate).year() : 'Today';
    var durationRender = moment.duration(content.LengthOfService, 'days').humanize() + ': ' + startDate.year() + ' - ' + endDateText;

    return `
		<li>
			<button class="service-history-item" data-index="${index}">
				<span class="circular-button">${index + 1}</span>
				<span class="service-history-summary">${content.ServiceTypeCode} ${durationRender}</span>
			</button>
		</li>
		`;
}

// Handle the interaction for mouse hover of the arc.
function onArcEnter(event) {
    const seriviceItems = document.querySelectorAll('.service-history-item');
    const dataIndex = parseIndex(event.currentTarget);
    highlightServiceItem(dataIndex, seriviceItems);
}

function onArcLeave(event) {
    const seriviceItems = document.querySelectorAll('.service-history-item');
    const arcPath = event.currentTarget;
    const dataIndex = parseIndex(arcPath);

    if (arcPath.classList && !arcPath.classList.contains('active')) {
        seriviceItems[dataIndex].setAttribute('class', 'service-history-item');
    }
}
// Handle the interaction for mouse hover of the service history item.
function onServiceItemEnter(event) {
    const allArcPath = document.querySelectorAll('.arc-path');
    const dataIndex = parseIndex(event.currentTarget);
    highlightArcPath(dataIndex, allArcPath);
}

function onServiceItemLeave(event) {
    const allArcPath = document.querySelectorAll('.arc-path');
    const serviceItem = event.currentTarget;
    const dataIndex = parseIndex(serviceItem);

    if (serviceItem.classList && !serviceItem.classList.contains('active')) {
        allArcPath[dataIndex].setAttribute('class', 'arc-path');
    }
}

// highlights the correct item that was clicked.
function highlighSelectedItem(activeIndex) {
    const allArcPath = document.querySelectorAll('.arc-path');
    const historyItems = document.querySelectorAll('.service-history-item');
    highlightServiceItem(activeIndex, historyItems);
    highlightArcPath(activeIndex, allArcPath);
}

// Find all the items in both the arc and the service item list and remove the highlight via css 'active class
function removeAllActiveItems() {
    const allArcPath = document.querySelectorAll('.arc-path');
    const historyItems = document.querySelectorAll('.service-history-item');
    // clear all active class on item.
    allArcPath.forEach((element) => element.setAttribute('class', 'arc-path'));
    historyItems.forEach((element) => element.setAttribute('class', 'service-history-item'));
}

// Find the arc-path that correspond to the service item clicked and highlight it using the 'active' css class.
function highlightArcPath(index, allArcPath) {
    allArcPath[index].setAttribute('class', 'arc-path active');
}

// Find the history item based on the index and add the highlight colour via the 'active' class
function highlightServiceItem(index, historyItems) {
    historyItems[index].setAttribute('class', 'service-history-item active');
}

// Find the data-index attribute value and return the integer version (base 10)
function parseIndex(item) {
    return item.getAttribute('data-index', 10);
}

function parseCoords(str) {
    var xy = str.split(',');
    const coords = { x: parseFloat(xy[0]), y: parseFloat(xy[1]) };
    return coords;
}
